// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { genericVars } from "./generic-variables";

export const environment = {
  appRegistrationPrefix: "T",
  azuretenant:"a7710ec2-1dbb-4980-9379-65fee8028a40",
  azureappid:"60ef0411-3424-4576-a2ee-9736881de6cc",
  consoleLogs: false,
  generic: genericVars,
  jeannieApiURL: 'https://app-connect-dev.sdc.computacenter.de',
  jeannieEnvironment: "Development",
  production: false,
  redirectURl:"https://ux-portal-dev.sdc.computacenter.de",
  toolbartitle: "User Experience Portal - Dev",
  welcomemessage: "Welcome to the User Experience Portal - Development",
};

export const azureFunctions = {
  portalFunctions : {
    baseUrl: "https://func-app-jafarportal-dev.azurewebsites.net/api",
    authCode: "2AaamP8L5j2zYW6V2OYmt4J9KjgkbnXg4O5JtYWIcre1AzFuwLdw_w==",
  },
  approvalFunctions : {
    baseUrl: "https://func-app-jafarportal-approvalprocess-dev.azurewebsites.net/api",
    authCode: "Fhy5YylrZRx8WWtdSTsM5X4Pbue-_sv_PFWe8JFhgjTdAzFuza86-A==",
  }
}
export const CIPSettings : CIPAPIConfig = {
  code            : "2AaamP8L5j2zYW6V2OYmt4J9KjgkbnXg4O5JtYWIcre1AzFuwLdw_w==",
  baseUrl         : "https://func-app-jafarportal-dev.azurewebsites.net/api",
  endPoints       : {
    changes             : "CIPChanges",
    changeDetails       : "CIPChangeDetails",
    incidents           : "CIPIncidents",
    incidentDetails     : "CIPIncidentDetails",
    requests            : "CIPRequests",
    requestDetails      : "CIPRequestDetails",
    requestTasks        : "CIPRequestTasks",
    requestTaskDetails  : "CIPRequestTaskDetails",
    envRoute            : "CIPEnvRoute",
    envRouteDefault     : "CIPEnvRouteDefault"
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
import { AppRegistration } from '../app/shared/datasource/interfaces/aad-group.interface';import { CIPAPIConfig } from "src/app/shared/datasource/interfaces/CIP/cip-general.interface";

