import { DevOpsConfig } from "src/app/shared/datasource/services/http-requests/azure-devops/workitem/workitem.interface"
import { PaginationOptions } from "src/app/shared/ui/reusable-table/interface/reusable-table.interface"

export const genericVars = {
    devops_baseurl: 'https://dev.azure.com/CCGDCore',
    devops_queryurl:'https://dev.azure.com/CCGDCore/Jeannie/_apis/wit/wiql',
    devops_workitemurl:'https://dev.azure.com/CCGDCore/Jeannie/_apis/wit/workitems',
    devops_token: "dXNlcjp0Z3k3YzNkemxwNmttam56NHViZ2p6YmQ3Yng1enBwc2UzeG94dnpvM3RyNWdwYWJwMnFh",
    devops_apiversion_7_1_p2: 'api-version=7.1-preview.2',
    devops_apiversion_7_1_p3: 'api-version=7.1-preview.3',
    graphApiUrl: 'https://graph.microsoft.com/v1.0',
    jeannieAuthUrl: "https://login.microsoftonline.com/a7710ec2-1dbb-4980-9379-65fee8028a40/oauth2/v2.0/token",
    jeannieApiVersion: 'v1',
    defaultPageSize: 15,
    pageSizeOptions: [10, 15, 20, 25, 50 ],
    logoUrlLight: "assets/computacenter/logos/logo_big_colour.png",
    logoUrlDark: "assets/computacenter/logos/logo_big_white.png",
    recaptcha: {
        siteKey: '6LfY8L0kAAAAAI4PPtINbOu3FEHHNHb6-lXlcZtB'
    },
    requestQAFilter: "MsgHeaderEventAction eq 'RequestQA'"
}

export const PaginationDefaults : PaginationOptions = {
    pageSize: 15,
    pageSizeOptions: [10, 15, 20, 25, 50 ]
}

export const DevOps : DevOpsConfig = {
  token             : "dXNlcjp0Z3k3YzNkemxwNmttam56NHViZ2p6YmQ3Yng1enBwc2UzeG94dnpvM3RyNWdwYWJwMnFh",
  baseUrl           : "https://dev.azure.com/CCGDCore",
  workItemEndpoint  : "_apis/wit/workitems",
  queryEndpoint     : "_apis/wit/wiql",
  apiVersion        : "api-version=7.1-preview.2",
  portalFeeback     : {
    projectName : "Jeannie",
    queryId     : "7682cdc9-58fd-4def-938f-9f8c45ee4fd8",
    type        : "$Task",
    area        : "Jeannie\\uxfeature",
    iteration   : "Jeannie\\Collection_FeaturesImprovement",
    tag         : "ssp_feature"
  },
  portalBug : {
    projectName : "Jeannie",
    queryId     : "74e9e4a2-0594-47af-81bc-6f70bd364484",
    area        : "Jeannie\\uxbug",
    iteration   : "Jeannie\\Collection_BugReport",
    tag         : "ssp_bug",
    type        : "$Bug"
  }

}
